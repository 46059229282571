import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Title from '../components/Title';
import PaginatedRoll from '../components/PaginatedRoll';
import constants from '../constants';

export default ({ data, pageContext }) => {
  return (
    <Layout>
      <Helmet titleTemplate="%s | ヒナタビ">
        <title>{pageContext.name}</title>
        <meta name="description" content={`${pageContext.name}の記事一覧`} />
      </Helmet>
      <Title>{pageContext.name}</Title>
      <PaginatedRoll
        type={constants.articleTypes.blog}
        data={data}
        pageContext={pageContext}
      />
    </Layout>
  );
};

export const query = graphql`
  query blogTagPage($skip: Int!, $limit: Int!, $name: [String]) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, tags: { in: $name } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            shrines
            blessings
            templateKey
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 120, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
